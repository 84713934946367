import { EditorSDK } from '@wix/platform-editor-sdk';
import { WIX_BLOG, WIX_EVENTS, WIX_FORUM, WIX_CHALLENGES } from '@wix/app-definition-ids';
import { IntegrationApplication, MembersAreaAppsOptions } from '@wix/members-area-app-definitions';
import { getAppDefinitions } from '@wix/members-area-app-definitions/dist/esm/getAppDefinition';
import { createInstance } from 'i18next';

import { getUniquePages } from '../../../../utils/pages';
import * as state from '../../../services/applicationState';
import { createBIService } from '../../../../utils/bi';
import { MembersAreaPagePublicApi } from '../../types';
import { APP_TOKEN, PROFILE_PAGE_BOB_APP_DEF_ID } from '../../../constants';

const getMembersAreaPagePublicApi = async (editorSDK: EditorSDK) => {
  const membersAreaPagePublicApi = await editorSDK.application.getPublicAPI(APP_TOKEN, {
    appDefinitionId: PROFILE_PAGE_BOB_APP_DEF_ID,
  });
  return membersAreaPagePublicApi as MembersAreaPagePublicApi | undefined;
};

export const registerMembersAreaApps = async (
  editorSDK: EditorSDK,
  applications: IntegrationApplication[],
  verticalAppDefId: string,
  applicationsOptions: MembersAreaAppsOptions,
) => {
  const applicationDefinitions = await getAppDefinitions({
    i18next: createInstance(),
    applications,
    editorSDK,
    applicationsOptions,
  });
  const currentIntegratedAppsMap = state.getAllIntegratedApps();
  const verticalsApps = getUniquePages([
    ...(currentIntegratedAppsMap[verticalAppDefId] ?? []),
    ...applicationDefinitions,
  ]);

  state.setIntegratedApps({
    ...currentIntegratedAppsMap,
    [verticalAppDefId]: verticalsApps,
  });

  // TODO: resetRolesOnSocialVertical alternative
};

// TODO: Add widgetId to integration kit
const getWidgetPluginKey = (appDefinitionId: string, pageId: string) => `${appDefinitionId}_${pageId}`;

const widgetPluginsMap: { [key in string]: string } = {
  [getWidgetPluginKey('14dbef06-cc42-5583-32a7-3abd44da4908', 'about')]: '14dbefb9-3b7b-c4e9-53e8-766defd30587',
  [getWidgetPluginKey('16ed1ac6-01cb-4fb6-a59e-c215cce8fdf6', 'My Rewards')]: 'c85a2896-9f7c-4cf1-9bf8-95852cc5219a',
  [getWidgetPluginKey(WIX_FORUM, 'member-posts-page')]: '14f0266c-e0a3-afc6-d07c-5be8e20949cd',
  [getWidgetPluginKey(WIX_FORUM, 'member-comments-page')]: '14f51b00-3ada-272e-b6db-34d5da9dd623',
  [getWidgetPluginKey(WIX_EVENTS, 'events_members_page')]: '405eb115-a694-4e2b-abaa-e4762808bb93',
  [getWidgetPluginKey('14ebe801-d78a-daa9-c9e5-0286a891e46f', 'following_followers')]:
    '14ebe825-b273-0d5f-0fd1-e6293322b83b',
  [getWidgetPluginKey(WIX_BLOG, 'member-comments-page')]: '14f260f9-c2eb-50e8-9b3c-4d21861fe58f',
  [getWidgetPluginKey(WIX_BLOG, 'member-likes-page')]: '14f26109-514f-f9a8-9b3c-4d21861fe58f',
  [getWidgetPluginKey(WIX_BLOG, 'member-posts-page')]: '14f26118-b65b-b1c1-b6db-34d5da9dd623',
  [getWidgetPluginKey(WIX_BLOG, 'member-drafts-page')]: '14f260e4-ea13-f861-b0ba-4577df99b961',
  [getWidgetPluginKey(WIX_BLOG, 'My Posts')]: 'e5a2773b-0e6b-4cbb-a012-3b4a69e92046',
  [getWidgetPluginKey(WIX_CHALLENGES, 'My Challenges')]: '384628b7-c716-4410-8fc5-4e2bd5aad178',
  [getWidgetPluginKey(WIX_CHALLENGES, 'My Programs')]: 'c88902a1-7fbf-4ff1-b19a-af39c48bc740',
};

export const installRegisteredApps = async (editorSDK: EditorSDK, verticalAppDefId: string) => {
  const biService = await createBIService({ editorSDK });
  biService.verticalTriggeredMaInstallInitiated({ originAppId: verticalAppDefId });

  const integrationApps = state.getVerticalsApps(verticalAppDefId) as IntegrationApplication[];
  const integrationAppsToInstall = integrationApps.filter(
    (app) =>
      app.shouldInstallInitially !== false && widgetPluginsMap[getWidgetPluginKey(app.appDefinitionId, app.pageId)],
  );

  if (integrationAppsToInstall.length > 0) {
    const membersAreaPagePublicApi = await getMembersAreaPagePublicApi(editorSDK);
    const widgetPlugins = integrationAppsToInstall.map((definition) => ({
      ...definition,
      widgetId: widgetPluginsMap[getWidgetPluginKey(definition.appDefinitionId, definition.pageId)],
    }));
    await membersAreaPagePublicApi?.addWidgetsPlugins(widgetPlugins);
  }

  // TODO: Set BWP on install

  biService.verticalTriggeredMaInstallSuccess({ originAppId: verticalAppDefId });
};
